import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import TypingAnimation from './Animation';
import NavBar from './NavBar';

import {
   
    Button,
   
  } from '@mui/material';
  import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TableSortLabel,
    TablePagination,
    Paper,
  } from '@mui/material';
import PhotoSlider from './slider';
export default function Results ( props )  {
    const [order, setOrder] = useState('asc');
    const [orderBy, setOrderBy] = useState('name');
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(17);
    const location = useLocation();
  const { state } = location;
  const [tab,setTab]=useState([])
  const [sports,setsportmeasurements]=useState({})
  
    const handleRequestSort = (property) => {
      const isAsc = orderBy === property && order === 'asc';
      setOrder(isAsc ? 'desc' : 'asc');
      setOrderBy(property);
    };
  
    const handleChangePage = (event, newPage) => {
      setPage(newPage);
    };
  
    const handleChangeRowsPerPage = (event) => {
      setRowsPerPage(parseInt(event.target.value, 10));
      setPage(0);
    };
    const measurements = Object.entries(sports).map(([key, value]) => ({
        name: key,
        value: value,
      }));
      
      console.log(measurements);
    const sortedMeasurements = [...measurements].sort((a, b) => {
      if (order === 'asc') {
        return a[orderBy] < b[orderBy] ? -1 : 1;
      } else {
        return a[orderBy] > b[orderBy] ? -1 : 1;
      }
    });
  
    const paginatedMeasurements = sortedMeasurements.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);
  
  
  useEffect(()=>{
    setTab(state.data.ReturnObject.fashion_measurements)
    setsportmeasurements(state.data.ReturnObject.sports_measurements)
  },[tab,sports])
  console.log(state.data)
  console.log(tab)
  console.log(sports)
  return (
    <div>
      <div style={{"backgroundColor":"#0d1e29"}}>
        <NavBar></NavBar>

        <TypingAnimation text="reesults" speed={50} />
    <br></br>
    
    <div style={{ padding: '16px', textAlign: 'center' }}>
    <PhotoSlider></PhotoSlider>
     <Button 
      sx={{
        backgroundColor: '#313133', // Red with 70% opacity
        '&:hover': {
          backgroundColor: '#030114', // Solid red on hover
        },
        color: 'white',
      }}
     variant="outlined" a href="/">With Images</Button>
     <Button 
     sx={{
        backgroundColor: '#313133', // Red with 70% opacity
        '&:hover': {
          backgroundColor: '#030114', // Solid red on hover
        },
        color: 'white',
      }}
     variant="contained" a href = "/withoutPhotos">Without Images</Button>
     <br></br><br></br>
     {state ? (
        <div>
          <p> </p>
          
        </div>
      ) : (
        <p>No data received</p>
      )}
      {
        tab ? (<div style={{ color:"white"}}>
            <span>fashion Measurements</span>
        <p> Shoulder Width :  {tab.Shoulder_Width} </p>
        <p> Arm Length     :  {tab.Arm_Length}   </p>
        <p> Leg Length     :  {tab.Leg_Length}   </p>
        <p> Trunk Length   :  {tab.Trunk_Length} </p>
        </div>    
        ) 

        :
        (
        <p>ksqdk</p>
        )
      }
      { sports ? (
        <Paper>
        <TableContainer>
          <Table style= {{"backgroundColor":"#0d1e29"}}>
            <TableHead>
              <TableRow>
                <TableCell>
                  <TableSortLabel
                  style = {{"color":"white"}}
                    active={orderBy === 'name'}
                    direction={orderBy === 'name' ? order : 'asc'}
                    onClick={() => handleRequestSort('name')}
                  >
                    Measurement
                  </TableSortLabel>
                </TableCell>
                <TableCell align="right">
                  <TableSortLabel
                    style = {{"color":"white"}}
                    active={orderBy === 'value'}
                    direction={orderBy === 'value' ? order : 'asc'}
                    onClick={() => handleRequestSort('value')}
                  >
                    Value (cm)
                  </TableSortLabel>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody style={{"borderColor":"white"}}>
              {paginatedMeasurements.map((measurement) => (
                <TableRow key={measurement.name} style = {{"color":"white"}} >
                  <TableCell style = {{"color":"whitesmoke"}} component="th" scope="row">
                    {measurement.name}
                  </TableCell>
                  <TableCell align="right" style = {{"color":"whitesmoke"}}>{measurement.value}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={measurements.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
      ):(<div></div>)}
    </div></div>
      
    </div>
  );


    

}