import axios from "axios";
const apiController = {

    async GetMeasurments(height,weight,front_image_64,side_image_64,gender) {
             
        try {
            console.log(height,weight,front_image_64,side_image_64,gender)
            const url = "https://services-admin-api.weefizz.ai/api"
            const response = await axios.post(`${url}`,  {
                "RequestType":"Estimate_Measurements","Parameters":
                {"height":height,"weight":weight,
                "measurement_options":[],
            "front_image":front_image_64,
            "side_image":side_image_64,
            "gender":gender.toString()
            }
        }
            
               
            
        )
         return response   
            
          
            
        } catch (error) {
            console.error('Error uploading image:', error);
           // Propagate the error
        }
    },
    async GetMeasurmentsWithoutPhotos(height,weight,gender,age,body_type,predictor){
        const url = "https://services-admin-api.weefizz.ai/api"
        const response = await axios.post(`${url}`,  {
            "RequestType":"Estimate_Measurements_Without_Photos","Parameters":
            {
               "height":height,
               "weight":weight,
               "gender":gender,
               "measurement_options":[],
               "age":age,
               "body_type":body_type,
               "predictor":predictor
            }
    })
    return response
    },

};
export default apiController;
//29797443