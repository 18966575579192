// FormComponent.js
import React, { useState } from 'react';
import { TextField, Button } from '@mui/material';

import { Radio, RadioGroup, FormControlLabel, FormControl, FormLabel } from '@mui/material';
import {Select , MenuItem} from '@mui/material'
import apiController from '../Controllers/api_controller';
import { useNavigate } from 'react-router-dom';



export default function WithoutPhotosFormComponent() {
  const navigate = useNavigate()
  const [formData, setFormData] = useState({ height: '', Weight: '' , Age:'' });
  const [error, setError] = useState({ height: false, Weight: false , Age : false });
  const [selectedOption, setSelectedOption] = useState('');
  const [MBT,setMBT]=useState(false)
  const [FBT,setFBT]=useState(false)
  const [selectedOption1, setSelectedOption1] = useState('');
  const [predictor,setPredictor]=useState(1)
  const [body_type,setBody_type]=useState(1)
  const [response,setresponse]=useState({"data":{},"status":400});
    const handleChangeSelect = (event) => {
        setSelectedOption1(event.target.value);
        if(event.target.value === "ectomorph"){
          setBody_type(1)
        }
        if(event.target.value === "mesomorph"){
          setBody_type(2)
        }
        if(event.target.value === "endomorph"){
          setBody_type(3)
        }
        if(event.target.value === "Triangle")
        {
          setBody_type(1)
        }
        if( event.target.value ===   "InvertedTriangle"){
          setBody_type(2)
        } 
        if( event.target.value ===   "Column") {
          setBody_type(3)
        }
        if( event.target.value ===    "Hourglass") {
          setBody_type(4)
        }
        if( event.target.value === "Diamond" ) {
          setBody_type(5)
        }
        if(event.target.value  === "Rounded") {
          setBody_type(6)
        }
    };
  const handleOptionChange = (event) => {
    console.log(event.target.value)
    setSelectedOption(event.target.value);
    if(event.target.value==="male"){
      setMBT(true)
      setFBT(false)
    }
    else{
      setFBT(true)
      console.log(FBT)
      setMBT(false)
    }

  };

  const handleChange = (e) => {
    const { name, value } = e.target;

    setFormData((prev) => ({ ...prev, [name]: value }));

    
    setError((prev) => ({ ...prev, [name]: false }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    // Validate form fields
    const newError = { height: !formData.height, Weight: !formData.Weight };
    
    if (newError.height || newError.Weight) {
      setError(newError); // Set errors if any field is empty
      return;
    }

    console.log('Form submitted:', formData); // Handle submission here
    setFormData({ height: '', Weight: '' }); // Reset form fields after submission
  };
  const predict1 = async(e) => {
        e.preventDefault()
        setPredictor(1)
        
        let gender = 2
      if(FBT === true){
        gender = 0  
      }
      if(MBT === true) {
         gender =1 
      }
      console.log(formData,gender,body_type)
      const r = await apiController.GetMeasurmentsWithoutPhotos(formData.height,formData.Weight,gender,formData.Age,body_type,1)
      console.log(r)

      setresponse(
        {
          "data":JSON.parse(r.data.substring(1)),
          "status":r.status
        })
        
            }
  
      console.log(response)
      console.log(response.status)
      if(response.status == 200)
        {
         navigate("/results",{state:response})
        }
  
  const predict2 = async (e) => 
    {
     e.preventDefault()
     setPredictor(2)
     let gender = 2
     if(FBT === true){
      gender = 0  
     }
     if(MBT === true) {
       gender =1 
     }
     console.log(formData,gender,body_type)
     const r = await apiController.GetMeasurmentsWithoutPhotos(formData.height,formData.Weight,gender,formData.Age,body_type,2)
     console.log(r)
     setresponse
     (
      {
        "data":JSON.parse(r.data.substring(1)),
        "status":r.status
      }
     )
    }

  return (
    <form onSubmit={handleSubmit} style={{ marginTop: '20px', maxWeight: '400px', margin: '0 auto' }}>
      <TextField
        label="Age"
        variant="outlined"
        fullWeight
        size="small"
        name="Age" // This name matches the state key
        value={formData.Age}
        onChange={handleChange} // Calls handleChange to update state
        error={error.Age} // Displays error if height is empty
        helperText={error.Age ? 'Age is required' : ''}
        style={{ marginBottom: '16px'  ,width:"500px",backgroundColor:"white"}}
      />
      <br></br><br></br>
      <TextField
        label="Height"
        variant="outlined"
        fullWeight
         size="small"
        name="height" // This name matches the state key
        value={formData.height}
        onChange={handleChange} // Calls handleChange to update state
        error={error.height} // Displays error if height is empty
        helperText={error.height ? 'Height is required' : ''}
        style={{ marginBottom: '16px'  ,width:"500px",backgroundColor:"white"}}
      />
      <br></br><br></br>
      <TextField
        label="Weight"
        variant="outlined"
        fullWeight
         size="small"
        name="Weight" // This name matches the state key
        value={formData.Weight}
        onChange={handleChange} // Calls handleChange to update state
        error={error.Weight} // Displays error if Weight is empty
        helperText={error.Weight ? 'Weight is required' : ''}
        style={{ marginBottom: '16px' , width:"500px",backgroundColor:"white"}}
      />
      
       
      <br></br><br></br>
      <FormControl component="fieldset">
      <FormLabel component="legend" style={{color:"white"}} >Select Your Gender:</FormLabel>
      <RadioGroup value={selectedOption} onChange={handleOptionChange}>
        <FormControlLabel value="male" style={{color:"white"}} control={<Radio />} label="Male" />
        <FormControlLabel value="female" style={{color:"white"}} control={<Radio />} label="Female" />
              </RadioGroup>
      
      <div>
      {MBT === false  && FBT === false ? (<div> </div> )  : 
       MBT === true  && FBT === false ? (<div>
        <span style={{color:"white"}}>Male Morphology</span>
       <Select
           labelId="select-label"
           value={selectedOption1}
           onChange={handleChangeSelect}
           label="Choose an option"
       >
           <MenuItem value="">
               <em>None</em>
           </MenuItem>
           <MenuItem value="ectomorph">H / Ectomorph</MenuItem>
           <MenuItem value="mesomorph">V / Mesomorph</MenuItem>
           <MenuItem value="endomorph">O / Endomorph</MenuItem>
       </Select>
       <br></br><br></br>
       <Button variant="contained" onClick={predict1} >Predictor 1</Button>
       <Button variant="contained" onClick={predict2} >Predictor 2</Button>
       </div>
      ) : (<div style={{color:"white"}}>
        <span style={{color:"white"}}>Female Morphology</span>
        <Select
           labelId="select-label"
           value={selectedOption1}
           onChange={handleChangeSelect}
           label="Choose an option"

       >
           <MenuItem value="">
               <em>None</em>
           </MenuItem>
           <MenuItem value="Triangle">A / Triangle / Pear</MenuItem>
           <MenuItem value="InvertedTriangle">V / InvertedTriangle</MenuItem>
           <MenuItem value="Column">H / Rectangle /Column</MenuItem>
           <MenuItem value="Hourglass">X / Hourglass</MenuItem>
          
           <MenuItem value="Diamond">B /Diamond</MenuItem>
           <MenuItem value="Rounded">O / Rounded / Apple </MenuItem>
       </Select>
       <br></br><br></br>
       <Button variant="contained" onClick={predict1} >Predictor 1</Button>
       <Button variant="contained" onClick={predict2} >Predictor 2</Button>
       </div>
      )}
      </div>
    </FormControl>
    <br></br><br></br>
      <Button type="submit" variant="contained" color="primary" fullWeight>
        Submit
      </Button>
    </form>
  );
}
