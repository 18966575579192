import React from 'react';
import TypingAnimation from './Animation';
import NavBar from './NavBar';
import AppForm from './Form';
import {
    
    Button,
   
  } from '@mui/material';
import PhotoSlider from './slider';

export default function Content(){


  return (
    <div style={{"backgroundColor":"#0d1e29"}}>
        <NavBar></NavBar>

        <TypingAnimation text="weelcome to weefizz AI" speed={50} />
    <br></br>
    
    <div style={{ padding: '16px', textAlign: 'center' }}>
    <PhotoSlider></PhotoSlider>
     <Button 
      sx={{
        backgroundColor: '#313133', // Red with 70% opacity
        '&:hover': {
          backgroundColor: '#030114', // Solid red on hover
        },
        color: 'white',
      }}
     variant="outlined" a href="/">With Images</Button>
     <Button 
     sx={{
        backgroundColor: '#313133', // Red with 70% opacity
        '&:hover': {
          backgroundColor: '#030114', // Solid red on hover
        },
        color: 'white',
      }}
     variant="contained" a href = "/withoutPhotos">Without Images</Button>
     <br></br><br></br>
     <AppForm></AppForm>
    </div>
      
    </div>
  )

}