// FileUploadComponent.js
import React, { useState } from 'react';
import { Button, Typography } from '@mui/material';

export default function FileUpload({ onFileChange , onFileChange1 }) {
  const [fileName, setFileName] = useState('');
  const [fileName1, setFileName1] = useState('');
  const handleFileChange = (e) => {
    e.preventDefault()
    console.log(e.target)
    const file = e.target.files[0];
    console.log(e.target.files)
    if (file) {
      setFileName(file.name);
      const reader = new FileReader();
      reader.onloadend = () => {
          // Pass the Base64 string back to the parent
          console.log(reader.result)
          onFileChange(reader.result);
      };
      reader.readAsDataURL(file);
      // Set the file name to state
    }
  };
  const handleFileChange1 = (e) => {
    e.preventDefault()
    console.log(e.target)
    const file = e.target.files[0];
    console.log(e.target.files)
    if (file) {
      setFileName1(file.name);
      const reader = new FileReader();
      reader.onloadend = () => {
          // Pass the Base64 string back to the parent
          console.log(reader.result)
          onFileChange1(reader.result);
      };
      reader.readAsDataURL(file);
      // Set the file name to state
    }
  };
  console.log(fileName)
  console.log(fileName1)
  return (
    <div style={{ marginTop: '20px', textAlign: 'center' }}>
      <span style={{color:"white"}}>Front Image</span>

      <input
        accept="image/*"
        type="file"
        id="file-upload-front"
        onChange={handleFileChange}
        style={{ display: 'none' }}
      />
      <label htmlFor="file-upload-front">
        <Button variant="contained" component="span"  >
          Upload Image
        </Button>
      </label>
      {fileName && (
        <Typography variant="body1" style={{ marginTop: '10px' }}>
          Uploaded File: {fileName}
        </Typography>
      )}
      <br></br><br></br>
      <span style={{color:"white"}}>Side Image</span>

      <input
        accept="image/*"
        type="file"
        id="file-upload-side"
        onChange={handleFileChange1}
        style={{ display: 'none' }}
      />
      <label htmlFor="file-upload-side">
        <Button variant="contained" component="span"  >
          Upload Image
        </Button>
      </label>
      
      {fileName1 && (
        <Typography variant="body1" style={{ marginTop: '10px' }}>
          Uploaded File: {fileName1}
        </Typography>
      )}
    </div>
  );
}
